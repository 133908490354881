export default {
  home: {
    en: 'Home',
  },
  about: {
    en: 'About',
  },
  buildersHub: {
    en: 'Builder’s hub',
  },
  docs: {
    en: 'Docs',
  },
  devHub: {
    en: 'Developer Hub',
  },
  azur: {
    en: 'AZUR',
  },
  airdrop: {
    en: 'Rewards & Airdrops',
  },
  vesting: {
    en: 'AZUR Vesting',
  },
  explore: {
    en: 'Explore',
  },
  ecosystem: {
    en: 'Ecosystem',
  },
  blog: {
    en: 'Blog',
  },
  duneDashboard: {
    en: 'Dune Dashboard',
  },
  community: {
    en: 'Community',
  },
  azuroscan: {
    en: 'Azuroscan',
  },
  pools: {
    en: 'Liquidity Pools',
  },
  portal: {
    en: 'Azuro Portal',
  },
  dexFarming: {
    en: 'DEX Liquidity Farming',
  },
  waves: {
    en: 'Azuro Waves',
  },
  apps: {
    en: 'Apps',
  },
  audit: {
    en: 'Audit Reports',
  },
  govern: {
    en: 'Governance',
  },
  privacy: {
    en: 'Privacy Policy',
  },
  terms: {
    en: 'Terms of Use',
  },
  staking: {
    en: 'AZUR Staking',
  },
}
